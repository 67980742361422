<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            积分明细
        </div>
        <div class="vip-content-inner">
            <div class="vip-table-wrap">
                <table class="vip-table">
                    <thead>
                    <tr>
                        <td>积分项目</td>
                        <td>数量</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in recordpPage" :key="index">
                        <td width="500">
                            <div>{{ item.remarks }}</div>
                            <div class="fts12">{{item.createTime}}</div>
                        </td>
                        <td :style="item.type==0?'color: #ea6100;':'color: #0a8483;'">{{item.type==0?'+':'-'}}{{ item.integral }}</td>
                    </tr>
                    </tbody>
                </table>
                <div class="mat20 text_cen" v-if="recordpPage.length==0">暂无数据</div>
            </div>
            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="pageSizes"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {integralRecordpPage} from "@/api/transaction";

export default {
    name: "history",
    data() {
        return {
            recordpPage:[],

            current:1, // 页数
            size:10, // 每页条数
            pageSizes:[10, 20, 50],
            total:0, // 数据总数
        }
    },
    mounted() {
        this.getintegralRecordpPage()
    },
    methods:{
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getintegralRecordpPage();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getintegralRecordpPage();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getintegralRecordpPage();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getintegralRecordpPage();
        },

        // -------------接口请求
        getintegralRecordpPage(){
            let param={
                current:this.current,
                size:this.size,
                customerId:localStorage.getItem('customId'),
            }
            integralRecordpPage(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    console.log('积分记录',res.data.data)
                    this.recordpPage=res.data.data.records
                    this.total=Number(res.data.data.total)

                }
            }).catch(err=>{
            })
        },
    },
}
</script>

<style scoped>

</style>
